<template>
  <div>
    <FilterBox @on-change="change" />

    <Box :title="$t('0b2e1a0')">
      <div slot="side">
        <Publish release-info-path="/content/service/release" :check-lawyer="true">
          <el-button class="btn" icon="el-icon-s-promotion">
            <span>{{ $t('3f8bf39') }}</span>
          </el-button>
        </Publish>
      </div>
      <div v-loading="loading">
        <ServiceItem
          v-for="item in list"
          :key="item.id"
          :data="item"
          @delete="getList(1)"
          @editSuccess="editSuccess"
        />
        <Empty v-if="!list.length" type="service" />
      </div>
      <div class="pager">
        <el-pagination
          :current-page="query.page"
          :page-size="query.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getList"
        />
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import ServiceItem from './ServiceItem'
import FilterBox from './filter'
import Api from '@/api/service'
import Publish from '@/components/PermissionContainer/Publish'
import Empty from '@/components/Empty/Factory'

export default {
  components: {
    Box,
    ServiceItem,
    FilterBox,
    Publish,
    Empty,
  },
  data() {
    return {
      list: [],
      loading: false,
      total: 0,

      query: {
        country: '',
        serviceType: '',
        startTime: '',
        endTime: '',
        // keyword: '',
        page: 1,
        size: 8,
      },
    }
  },
  mounted() {
    this.getList(1)
  },
  methods: {
    editSuccess() {
      this.getList()
    },
    getList(page) {
      this.loading = true
      const data = { ...this.query, page }
      console.log('xx1', data)
      Api.getLawUserList(data)
        .then((res) => {
          this.query.page = page
          this.list = res.content
          this.total = res.page.total
        })
        .then((res) => {
          this.loading = false
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    change(val) {
      this.query = { ...this.query, ...val }
      this.getList(1)
    },
  },
}
</script>

<style lang="less" scoped>
.btn {
  padding: 0 10px;
  height: 32px;
  //width: 120px;
  color: #00a4ff;
  border-color: #00a4ff;
}

.empty {
  padding: 30px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.pager {
  padding: 20px 20px 0;
  text-align: right;
}
</style>
