<template>
  <Box>
    <div slot="header"></div>
    <el-form label-width="110px" label-suffix="：" :model="query" :label-position="labelPosition">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('5cb0761')">
            <el-select
              v-model="query.country"
              placeholder="请选择服务国家"
              style="width: 280px"
            >
              <el-option value="" :label="$t('8224500')" />
              <el-option
                v-for="item in countrys"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :style="{'text-align': isInZh ? 'right' : 'left'}">
          <el-form-item :label="$t('f2752de')">
            <el-select
              v-model="query.businessTypeId"
              :placeholder="$t('6fd5fc3')"
              style="width: 280px"
            >
              <el-option value="" :label="$t('8224500')" />
              <el-option
                v-for="item in fieldOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('122cefe')">
            <el-date-picker
              v-model="query.time"
              style="width: 280px"
              type="daterange"
              :start-placeholder="$t('c01cae1')"
              :end-placeholder="$t('0e0d505')"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12" :style="{'text-align': isInZh ? 'right' : 'left', 'margin-top': isInZh ? '0px' : '48px'}">
          <el-button type="primary" @click="change('submit')">{{ $t('79dc812') }}</el-button>
          <el-button @click="change('reset')">{{ $t('1c4056e') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </Box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Box from '@/components/Box'
import ApiCom from '@/api/common'
import { SERVICE_TYPE } from '@/utils/constants'

const initQuery = () => ({
  country: '',
  serviceType: '',
  businessTypeId: '',
  time: '',
  // keyword: '',
})

export default {
  components: {
    Box,
  },
  data() {
    return {
      countrys: [],
      types: SERVICE_TYPE,
      query: initQuery(),
      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(23)
          return date > today
        },
      },
      labelPosition: localStorage.getItem("lang") === "zh" ? "right" : "top",
      isInZh: localStorage.getItem("lang") === "zh"
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictionaries']),
    fieldOptions() {
      const dicts = this.dictionaries
      const type = this.userInfo.userType
      const dic =
        type === 'LAW_OFFICE' || type === 'LAWYER'
          ? dicts['BUSINESS_TYPE']
          : dicts['TRANSLATION_BUSINESS_TYPE']
      return dic ? dic.list : []
    },
  },
  beforeUpdate() {
    this.labelPosition = localStorage.getItem("lang") === "zh" ? "right" : "top"
    this.isInZh = localStorage.getItem("lang") === "zh"
  },
  mounted() {
    this.getCountry()
    this.getDictionary({ type: 'BUSINESS_TYPE' })
    this.getDictionary({ type: 'TRANSLATION_BUSINESS_TYPE' })
  },
  methods: {
    ...mapActions(['getDictionary']),
    getCountry() {
      ApiCom.getDictionaryList({ type: 'COUNTRY' }).then((res) => {
        this.countrys = res.content
      })
    },
    change(type) {
      if (type === 'reset') {
        this.query = initQuery()
      }

      const { time, ...query } = this.query
      const params = {
        ...query,
        startTime: time[0] || '',
        endTime: time[1] || '',
      }

      this.$emit('on-change', params, type)
    },
  },
}
</script>
