<template>
  <section class="serv-item">
    <div v-if="data.status == 'REVIEWING'" class="tag tag-warning">
      {{ $t('e995e23') }}
    </div>
    <div v-if="data.status == 'PASS'" class="tag tag-info">
      {{ $t('6bb96c5') }}
    </div>
    <div v-if="data.status == 'REJECTED'" class="tag tag-danger">
      {{ $t('f766986') }}
    </div>

    <div class="actions">
      <el-popconfirm title="确定删除" @confirm="onDelete(data)">
        <i slot="reference" class="el-icon-delete"></i></el-popconfirm>
      <i class="el-icon-edit-outline" @click="jumpEdit(data.id)"></i>
    </div>

    <div class="desc">
      <el-row>
        <el-col :span="24">
          <DescItem :label="$t('9c1b456')">{{ data.title }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('9da0738')">{{ data.code }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('5cb0761')">{{ data.country }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('f2752de')">{{ data.businessType }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('122cefe')">{{ data.createTime | date('YYYY-MM-DD') }}</DescItem>
        </el-col>
        <el-col>
          <DescItem :label="$t('e650329')" :line-count="2">
            {{ data.content }}
          </DescItem>
        </el-col>
      </el-row>

    </div>

    <!-- <i class="line"></i>

    <div class="desc">
      <div class="item" v-for="item in 6" :key="item">
        <div class="label">服务标题：</div>
        <div class="cont">啦啦啦啦啦了</div>
      </div>
      <div class="item item-single">
        <div class="label">服务标题：</div>
        <div class="cont">
          某船运公司与某贸易公司存在长期业务往来，但未签订过书面合同,某船运公司与某贸易公司存在长期业务往来，但未签订过书面合同。某船运公司与某贸易公司存在长期业务往来，但未签订过书面合同。某船运公司与某贸易公司存在长期业某船运公司与某贸易公司存在长期业务往来，但未签订过书面合同,某船运公司与某贸易公司存在长期业务往来，但未签订过书面合同。某船运公司与某贸易公司存在长期业务往来，但未签订过书面合同。某船运公司与某贸易公司存在长期业
        </div>
      </div>
    </div> -->

    <footer class="foot">
      <div class="more" @click="jumpDetail(data.id)">
        {{ $t('696c3e0') }} <i class="icon"></i>
      </div>
    </footer>

    <ReleaseFormDialog v-if="dialogData.dialogVisible" :dialog-data="dialogData" @editSuccess="editSuccess" />
  </section>
</template>

<script>
import ReleaseFormDialog from '@/components/ReleaseFormDialog'
import Api from '@/api/service'
import DescItem from "@/components/Descriptions/Item"

export default {
  components: {
    ReleaseFormDialog,
    DescItem,
  },
  props: { data: Object },
  data() {
    return {
      dialogData: {
        dialogVisible: false,
        title: '编辑服务',
        type: 'ReleaseDesk',
        id: '',
      },
    }
  },
  methods: {
    editSuccess() {
      this.$emit('editSuccess')
    },
    jumpDetail(id) {
      this.$router.push(`/content/service/detail?id=${id}`)
    },
    jumpEdit(id) {
      console.log('xid', id)
      this.dialogData.id = id
      this.dialogData.dialogVisible = true
      // this.$router.push(`/content/service/release?id=${id}`);
    },
    onDelete(data) {
      Api.deleteLaw(data.id).then((res) => {
        this.$message.success('删除成功')
        this.$emit('delete', res)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.serv-item {
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  padding-right: 30px;
  margin-bottom: 20px;

  .tag {
    display: inline-block;
    margin: 20px 0;
    //width: 114px;
    padding: 0 30px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 0 100px 100px 0;
    font-size: 18px;
    font-weight: 500;
    background: #f2f2f2;
    color: #c3c3c3;

    &-warning {
      background: rgba(#faad14, 0.07);
      color: #faad14;
    }

    &-info {
      background: rgba(#00a4ff, 0.07);
      color: #00a4ff;
    }

    &-success {
      background: rgba(#51c518, 0.07);
      color: #51c518;
    }

    &-danger {
      background: rgba(#f5222d, 0.07);
      color: #f5222d;
    }
  }

  .actions {
    position: absolute;
    top: 30px;
    right: 30px;

    i {
      font-size: 16px;
      color: #666;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .line {
    margin: 14px 30px 30px;
    display: block;
    border-bottom: 1px dashed #eee;
  }

  .desc {
    display: flex;
    flex-flow: row wrap;
    padding: 0 30px;

    .label {
      width: 100px;
      text-align: right;
      color: #999999;
    }

    .cont {
      min-width: 0;
      flex: 1;
    }
  }

  .foot {
    padding: 14px 0 26px;
    display: flex;
    justify-content: center;

    .more {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      color: #00a4ff;
      cursor: pointer;
    }

    .icon {
      position: relative;
      display: block;
      width: 12px;
      height: 12px;
      border: 1px solid #00a4ff;
      border-radius: 50%;
      margin-left: 8px;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        margin-left: -1px;
        width: 4px;
        height: 4px;
        transform: translate(-50%, -50%) rotate(45deg);
        border-top: 1px solid #00a4ff;
        border-right: 1px solid #00a4ff;
        // border-radius: 2px;
      }
    }
  }
}
</style>
